import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { includes, without } from "lodash";
import PropTypes from "prop-types";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import { PostcodeModels } from "~/models";
import {
  $_IS_NOT_VALID,
  $_MUST_BE_ENTERED,
  FILTERS,
  LOCKER,
  POSTCODE,
  SHOP,
} from "~/constants/strings";
import {
  PICKUP_POINT_AMENITIES_LABELS,
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_TYPE,
} from "~/constants/pickupPoint";
import { Check, Filter, Search } from "~/components/Icon";
import { formatMessage } from "~/utils/string";

import styles from "./Filters.module.scss";

const SearchInput = ({ onSubmit, deliveryAddress }) => {
  const [postcode, setPostcode] = useState(deliveryAddress.postcode || "");
  const [active, setActive] = useState(false);

  const isPostcodeValid =
    !!postcode && PostcodeModels.isGBPostCodeFormat(postcode);

  const showError = !active && !isPostcodeValid;
  const errorMessage = postcode
    ? formatMessage($_IS_NOT_VALID, POSTCODE, "")
    : formatMessage($_MUST_BE_ENTERED, POSTCODE);

  return (
    <form
      className={classNames(
        "d-flex align-items-center mr-2",
        active && "border-primary",
        showError && "border-danger",
        styles.searchInputContainer
      )}
    >
      <FormControl
        type="text"
        label="Postcode"
        onKeyDown={event => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.target.blur();
            isPostcodeValid && onSubmit(postcode);
          }
        }}
        required
        classes={{
          group: classNames("mb-0", styles.searchInput),
          input: classNames(styles.searchInput),
        }}
        value={postcode}
        meta={{
          active,
          touched: true,
          error: showError && errorMessage,
        }}
        onBlur={() => setActive(false)}
        onFocus={() => setActive(true)}
        onChange={setPostcode}
      />

      <div onClick={() => isPostcodeValid && onSubmit(postcode)} type="submit">
        <Search active={active} invalid={showError} />
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  deliveryAddress: PropTypes.object,
};

const Filters = ({
  selectedTypes,
  setSelectedTypes,
  selectedAmenities,
  setSelectedAmenities,
  deliveryAddress,
  onSearchPickupLocations,
}) => {
  const isShopFilterSelected = includes(selectedTypes, PICKUP_POINT_TYPE.SHOP);
  const isLockerFilterSelected = includes(
    selectedTypes,
    PICKUP_POINT_TYPE.LOCKER
  );

  const AmenitiesFilterButton = ({ id, label }) => {
    const isChecked = includes(selectedAmenities, id);

    return (
      <div
        className={classNames(
          styles.dropdownButton,
          styles.amenitiesFilterButton,
          "py-2 px-4"
        )}
        onClick={() =>
          setSelectedAmenities(
            isChecked
              ? without(selectedAmenities, id)
              : [...selectedAmenities, id]
          )
        }
      >
        <span className="mr-1">{label}</span>
        {isChecked && <Check className={styles.checkIcon} />}
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-between px-4 py-3 bg-white">
      <SearchInput
        className="mr-2"
        deliveryAddress={deliveryAddress}
        onSubmit={onSearchPickupLocations}
      />
      <div className="d-flex">
        <Dropdown autoClose="inside" className="mr-2">
          <Dropdown.Toggle
            variant="outline-dark"
            id="dropdown-autoclose-inside"
            className={classNames(
              styles.toggleButton,
              styles.amenitiesFilterToggle,
              "d-flex align-items-center rounded"
            )}
          >
            <span className="mr-1">{FILTERS.toUpperCase()}</span>
            <Filter size="20" />
          </Dropdown.Toggle>

          <Dropdown.Menu className={styles.amenitiesFilterMenu}>
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING}
              label={PICKUP_POINT_AMENITIES_LABELS.LABEL_PRINTING}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_LATE}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING}
              label={PICKUP_POINT_AMENITIES_LABELS.CAR_PARKING}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SATURDAYS}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SUNDAYS}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS}
              label={PICKUP_POINT_AMENITIES_LABELS.WHEELCHAIR_ACCESS}
            />
          </Dropdown.Menu>
        </Dropdown>
        <Button
          className={classNames(
            "mr-2 w-100 rounded",
            !isShopFilterSelected && styles.filterButton
          )}
          variant={isShopFilterSelected ? "danger" : "outline-dark"}
          onClick={() =>
            isShopFilterSelected
              ? setSelectedTypes(without(selectedTypes, PICKUP_POINT_TYPE.SHOP))
              : setSelectedTypes([...selectedTypes, PICKUP_POINT_TYPE.SHOP])
          }
        >
          {SHOP.toUpperCase()}
        </Button>
        <Button
          className={classNames(
            "w-100 rounded",
            !isLockerFilterSelected && styles.filterButton
          )}
          variant={isLockerFilterSelected ? "danger" : "outline-dark"}
          onClick={() =>
            isLockerFilterSelected
              ? setSelectedTypes(
                  without(selectedTypes, PICKUP_POINT_TYPE.LOCKER)
                )
              : setSelectedTypes([...selectedTypes, PICKUP_POINT_TYPE.LOCKER])
          }
        >
          {LOCKER.toUpperCase()}
        </Button>
      </div>
    </div>
  );
};

Filters.propTypes = {
  selectedTypes: PropTypes.string,
  setSelectedTypes: PropTypes.func,
  selectedAmenities: PropTypes.array,
  setSelectedAmenities: PropTypes.func,
  deliveryAddress: PropTypes.object,
  onSearchPickupLocations: PropTypes.func,
};

export default Filters;
