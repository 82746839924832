import {
  cloneDeep,
  filter,
  get,
  includes,
  isEmpty,
  isEqual,
  merge,
  omit,
  pick,
  uniqBy,
  values,
} from "lodash";
import {
  getFormAsyncErrors,
  getFormMeta,
  getFormNames,
  getFormSubmitErrors,
  getFormSyncErrors,
  getFormValues,
  hasSubmitFailed,
  isDirty,
  isValid,
} from "redux-form";
import { createSelector } from "reselect";

import { getSearchAddressDetails } from "~/components/SearchAddress/selectors";
import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_DETAILS,
  DELIVERY_DETAILS_SEARCH_FORM,
  EDIT_PRODUCT_FORM,
  EXPORTER_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INBOUND_CONSIGNMENT,
  InboundServicesRelatedFields,
  NOTIFICATION_DETAILS,
  OUTBOUND_CONSIGNMENT,
  OutboundServicesRelatedFields,
  ProductDescriptionsEntity,
  ProductEntity,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import {
  AddressbookModels,
  CardModels,
  CustomsModels,
  InvoiceModels,
  NewShipmentModels,
  ParcelModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import {
  AuthSelectors,
  ProfilesSelectors,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import {
  getAllObjectKeysWhereValueIsTrue,
  getDeepKeys,
  getValue,
  omitNilValues,
  replaceEmptyStringToUndefined,
} from "~/utils/object";

export const getPageConfig = (state, pageConfig) => pageConfig;

export const getShipment = (state, shipment) => shipment;

export const getFormState = (state, { formName }) =>
  get(state, `form.${formName}`, {});

export const getFormActiveField = createSelector(
  getFormState,
  ({ active }) => active
);

export const getRegisteredFields = createSelector(getFormState, state =>
  Object.keys(getValue(state, "registeredFields", {}))
);

export const getFields = (state, { formName }) => getFormMeta(formName)(state);

export const getShipmentFormSyncErrors = (state, { formName }) =>
  getFormSyncErrors(formName)(state) || {};

export const getParcelsProductsErrors = createSelector(
  getShipmentFormSyncErrors,
  errors => getValue(errors, "outboundConsignment.parcels", [])
);

export const getShipmentFormValues = (state, { formName }) =>
  getFormValues(formName)(state);

export const getShipmentFormSubmitErrors = (state, { formName }) =>
  getFormSubmitErrors(formName)(state) || {};

export const getShipmentFormAsyncErrors = (state, { formName }) =>
  getFormAsyncErrors(formName)(state) || {};

export const getTouchedErrorFields = namespace =>
  createSelector(
    getShipmentFormSyncErrors,
    getFields,
    (state, { formName }) => hasSubmitFailed(formName)(state),
    (errors, allFields, submitFailed) =>
      filter(
        getDeepKeys(errors),
        error =>
          error.includes(namespace) &&
          (submitFailed || get(allFields, `${error}.touched`))
      )
  );

export const getOutboundServicesForCurrentUser = createSelector(
  AuthSelectors.getAuthUser,
  ProfilesSelectors.getActiveProfile,
  ReferenceSelectors.getOutboundServices,
  UmsSelectors.getCustomer,
  getShipmentFormValues,
  (...arg) => ServiceModels.getServicesForUser(...arg, true)
);

export const getInboundServicesForCurrentUser = createSelector(
  AuthSelectors.getAuthUser,
  ProfilesSelectors.getActiveProfile,
  ReferenceSelectors.getInboundServices,
  UmsSelectors.getCustomer,
  getShipmentFormValues,
  ServiceModels.getServicesForUser
);

export const getProductsKeyValue = createSelector(
  getOutboundServicesForCurrentUser,
  (outboundServices = []) =>
    uniqBy(
      outboundServices.map(outbound => ({
        label: get(outbound, "product.productDesc"),
        value: get(outbound, "product.productKey"),
      })),
      "value"
    )
);

export const getInboundProductsKeyValue = createSelector(
  getInboundServicesForCurrentUser,
  (inboundServices = []) =>
    uniqBy(
      inboundServices.map(inbound => ({
        label: get(inbound, "product.productDesc"),
        value: get(inbound, "product.productKey"),
      })),
      "value"
    )
);

export const isValidReturnContactSection = createSelector(
  getShipmentFormSyncErrors,
  getFields,
  getShipmentFormValues,
  (errors, fields, formValues) => {
    const returnContactValues = {
      ...get(
        formValues,
        `${INBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`,
        {}
      ),
    };
    const returnContact = {
      ...get(
        fields,
        `${INBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`,
        {}
      ),
    };

    return !isEmpty(returnContact)
      ? isEmpty(
          pick(
            errors,
            values(
              ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS
                .CONTACT_DETAILS
            )
          )
        )
      : !isEmpty(returnContactValues);
  }
);

export const getSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getShipmentFormValues,
  (countries, formValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        )
    ) || {}
);

export const getSelectedReturnCountry = createSelector(
  ReferenceSelectors.getCountries,
  getShipmentFormValues,
  (countries, formValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(
          formValues,
          ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        )
    )
);

export const getData = state => state.ui.create;

export const isLoadingOutboundServices = createSelector(
  getData,
  ({ isLoadingOutboundServices = false }) => isLoadingOutboundServices
);

export const isLoadingInboundServices = createSelector(
  getData,
  ({ isLoadingInboundServices = false }) => isLoadingInboundServices
);

export const getUniqueSenderRef1 = createSelector(
  getData,
  ({ uniqueSenderRef1 }) => uniqueSenderRef1
);

export const getProductRelatedInboundServices = createSelector(
  getInboundServicesForCurrentUser,
  ReferenceSelectors.getActiveInboundService,
  (inboundServices, selectedService) => {
    const productCode = get(selectedService, "product.productKey");

    return ServiceModels.getProductRelatedServices(
      productCode,
      inboundServices
    );
  }
);

export const getNetworksKeyValue = createSelector(
  getOutboundServicesForCurrentUser,
  AuthSelectors.getAuthUser,
  outboundServices => ServiceModels.getNetworksKeyValue(outboundServices)
);

export const getInboundProductRelatedServicesKeyValue = createSelector(
  getProductRelatedInboundServices,
  inboundServices => ServiceModels.getServicesKeyValue(inboundServices)
);

export const getShipmentType = createSelector(
  getShipmentFormValues,
  ({ shipmentType } = {}) => shipmentType
);

export const getGenerateCustomData = createSelector(
  getShipmentFormValues,
  ({ generateCustomsData } = {}) => generateCustomsData
);

export const getShipmentTypes = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  UmsSelectors.getCustomerPrefs,
  AuthSelectors.getBusinessUnit,
  (service, { allowSwapit, allowAdhocReversIt }, businessUnit) => {
    const result = [];
    const collectOnDelivery = get(service, "collectOnDelivery", false);
    if (collectOnDelivery && allowSwapit && businessUnit === 1) {
      result.push(ShipmentModels.shipmentTypes.swapIt);
    }
    if (collectOnDelivery && allowAdhocReversIt) {
      result.push(ShipmentModels.shipmentTypes.reverseIt);
    }
    if (result.length > 0) {
      result.push(ShipmentModels.shipmentTypes.noType);
    }
    return result;
  }
);

export const getCustomsFieldsConfig = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  getSelectedCountry,
  getShipmentFormValues,
  UmsSelectors.getCustomer,
  CustomsModels.getCustomsFieldsConfig
);

// TODO: investigate why postCode=false and refactor
export const getCommonRequiredFields = createSelector(
  UmsSelectors.getShippingSettings,
  UmsSelectors.getCustomer,
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  (shippingSettings, customer, formValues, selectedService) => {
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const { sendersRef1, sendersRef2, sendersRef3 } = shippingSettings;
    const ordernoreq = getValue(customer, "ordernoreq", false);
    const networkKey = getValue(selectedService, "networkKey", "");
    const notifyRequired = ServiceModels.isFlagRequired(
      selectedService,
      "notifyRequired"
    );
    const isEmptyMobile =
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.MOBILE,
        ""
      ).trim().length === 0;
    const isEmptyEmail =
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.EMAIL,
        ""
      ).trim().length === 0;
    const hasShip2ShopNotify =
      ServiceModels.hasShipToShopNotify(selectedService);
    const isRequiredEmail =
      !(!isEmptyMobile && isEmptyEmail) &&
      !!networkKey.length &&
      (notifyRequired || hasShip2ShopNotify);
    const isRequiredMobile =
      !(!isEmptyEmail && isEmptyMobile) &&
      !!networkKey.length &&
      (notifyRequired || hasShip2ShopNotify);
    const productOnlyShipment =
      ServiceModels.isProductOnlyShipment(selectedService);
    const irelandDirect = ShipmentModels.isIrelandDirect(
      countryCode,
      getValue(selectedService, "product.productKey", "")
    );

    return {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL]: isRequiredEmail,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .MOBILE]: isRequiredMobile,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        false,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
        sendersRef1 ||
        ordernoreq ||
        networkKey.includes("^80") ||
        productOnlyShipment ||
        irelandDirect,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]: sendersRef2,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]: sendersRef3,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
        true,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]: true,
    };
  }
);

export const getAddressBookRequiredFields = createSelector(
  getSelectedCountry,
  getCommonRequiredFields,
  ShipmentModels.getAddressBookRequiredFields
);

export const getAddressBookErrors = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  getSelectedCountry,
  AuthSelectors.getAuthUser,
  UmsSelectors.getCustomer,
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  ReferenceSelectors.getCountries,
  getAddressBookRequiredFields,
  ShipmentModels.getAddressBookErrors
);

export const isDirtyShipmentForm = (state, { formName }) =>
  isDirty(formName)(state);

export const getShipmentProducts = createSelector(
  getShipmentFormValues,
  formValues => {
    const parcels = getValue(formValues, "outboundConsignment.parcels", []);
    return parcels.reduce((acc, parcel, packageNumber) => {
      parcel &&
        !isEmpty(parcel.products) &&
        acc.push(
          ...parcel?.products?.map((product, productIndex) => ({
            ...product,
            packageNumber: packageNumber.toString(),
            productIndex,
            productId: `${packageNumber}${productIndex}`,
          }))
        );
      return acc;
    }, []);
  }
);

export const getParcelsTotalValue = createSelector(
  getShipmentFormValues,
  shipment =>
    parseFloat(
      ParcelModels.calculateParcelsTotalValues(
        get(shipment, "outboundConsignment.parcels", [])
      )
    ).toFixed(2)
);

export const getShippingFreightCost = createSelector(
  getShipmentFormValues,
  shipment =>
    parseFloat(
      get(shipment, ShipmentEntity.INVOICE.SHIPPING_COST) || 0
    ).toFixed(2)
);

export const isProductsRequired = createSelector(
  UmsSelectors.getCustomer,
  ReferenceSelectors.getActiveOutboundService,
  getShipmentFormValues,
  NewShipmentModels.isProductsRequired
);

export const getParcelsDataErrors = createSelector(
  getShipmentFormValues,
  isProductsRequired,
  (formValues, isProductsRequired) => {
    const parcels = getValue(formValues, "outboundConsignment.parcels", []);
    const numberOfParcels = get(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      0
    );
    const shippingWeight = get(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      0
    );

    return isProductsRequired
      ? ParcelModels.getParcelsDataErrors(
          parcels,
          shippingWeight,
          numberOfParcels
        )
      : [];
  }
);

export const getNumberOfProductsErrors = createSelector(
  getShipmentFormValues,
  isProductsRequired,
  (formValues, isProductsRequired) => {
    const parcels = getValue(formValues, "outboundConsignment.parcels", []);
    const numberOfParcels = get(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      0
    );

    return isProductsRequired
      ? ParcelModels.getNumberOfProductsErrors(numberOfParcels, parcels)
      : [];
  }
);

export const isParcelsDataValid = createSelector(
  getParcelsDataErrors,
  getParcelsProductsErrors,
  getNumberOfProductsErrors,
  UmsSelectors.getCustomer,
  (parcelsErrors, parcelsProductsErrors, numberOfProductsErrors, customer) => {
    const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
    const isParcelsValid =
      parcelsErrors.length === 0 && parcelsProductsErrors.length === 0;

    return isNewVersion
      ? isParcelsValid
      : isParcelsValid && numberOfProductsErrors.length === 0;
  }
);

export const getProductHiddenFields = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  getGenerateCustomData,
  UmsSelectors.getCustomer,
  (service, generateCustomsData, customer) => {
    if (
      !generateCustomsData &&
      !ServiceModels.isFlagRequired(service, "prodRequired")
    ) {
      return {};
    }

    const isSkuHidden = ServiceModels.isFlagNeedles(service, "skuRequired");
    const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);

    return {
      [ProductEntity.PRODUCT_CODE]: isSkuHidden,
      [ProductEntity.PRODUCT_TYPE]: isNewVersion
        ? ServiceModels.isFlagNeedles(service, "productTypeRequired")
        : isSkuHidden,
      [ProductEntity.PRODUCT_COMPOSITION]: isNewVersion
        ? ServiceModels.isFlagNeedles(service, "compositionRequired")
        : isSkuHidden,
      [ProductEntity.PRODUCT_URL]: ServiceModels.isFlagNeedles(
        service,
        "urlRequired"
      ),
      [ProductEntity.COUNTRY_OF_ORIGIN]:
        isNewVersion && ServiceModels.isFlagNeedles(service, "countryOfOrigin"),
      [ProductEntity.COMMODITY_CODE]: ServiceModels.isFlagNeedles(
        service,
        "commodityRequired"
      ),
    };
  }
);

export const getProductInitialValues = createSelector(
  getProductHiddenFields,
  hiddenFields => ({
    packageNumber: "0",
    ...(!hiddenFields[ProductEntity.COUNTRY_OF_ORIGIN] && {
      [ProductEntity.COUNTRY_OF_ORIGIN]: S.GB,
    }),
  })
);

export const getProductRequiredFields = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  getGenerateCustomData,
  getSelectedCountry,
  UmsSelectors.getCustomer,
  (service, generateCustomsData, country, customer) => {
    const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);

    if (
      !generateCustomsData &&
      !ServiceModels.isFlagRequired(service, "prodRequired")
    ) {
      return {};
    }

    if (
      isNewVersion &&
      ServiceModels.isFlagDescription(service, "prodRequired")
    ) {
      return { [ProductEntity.PRODUCT_DESCRIPTION]: true };
    }

    const skuRequired = ServiceModels.isFlagRequired(service, "skuRequired");

    return {
      [ProductEntity.PRODUCT_CODE]: skuRequired,
      [ProductEntity.PRODUCT_TYPE]: isNewVersion
        ? ServiceModels.isFlagRequired(service, "productTypeRequired")
        : skuRequired,
      [ProductEntity.PRODUCT_DESCRIPTION]: true,
      [ProductEntity.PRODUCT_COMPOSITION]: isNewVersion
        ? ServiceModels.isFlagRequired(service, "compositionRequired")
        : skuRequired,
      [ProductEntity.COMMODITY_CODE]:
        ShipmentModels.isRequiredForService(service),
      [ProductEntity.UNIT_WEIGHT]: true,
      [ProductEntity.PRODUCT_QUANTITY]: true,
      [ProductEntity.UNIT_VALUE]: true,
      [ProductEntity.PRODUCT_URL]: ServiceModels.isFlagRequired(
        service,
        "urlRequired"
      ),
      [ProductEntity.COUNTRY_OF_ORIGIN]: isNewVersion
        ? ServiceModels.isFlagRequired(service, "countryOfOrigin")
        : true,
    };
  }
);

export const getProductRequiredFieldsArray = createSelector(
  getProductRequiredFields,
  getAllObjectKeysWhereValueIsTrue
);

export const isCustomsFieldsVisible = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  getSelectedCountry,
  getShipmentFormValues,
  UmsSelectors.getCustomer,
  CustomsModels.isCustomsFieldsVisible
);

export const getGenerateCustomsSettings = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  getShipmentFormValues,
  UmsSelectors.getCustomer,
  ShipmentModels.getGenerateCustomsDataSettings
);

export const isSwapItOrReverseItShipmentType = createSelector(
  getShipmentType,
  ShipmentModels.isSwapItOrReverseItShipmentType
);

export const getInvoiceExporterRequiredFields = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getCountries,
  ReferenceSelectors.getActiveOutboundService,
  UmsSelectors.getCustomer,
  (values, countries, selectedService, { shippingVersion }) => {
    const selectedCountry = countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(
          values,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
          ""
        )
    );
    const requiredFields = InvoiceModels.getExporterRequiredFields({
      formValues: values,
      paths: {
        [Fields.DESTINATION_TAX_ID_REG_NO]:
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
        [Fields.COUNTRY_CODE]:
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
      },
      selectedCountry,
      selectedService,
      shippingVersion,
    });

    return {
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE]:
        requiredFields[Fields.COUNTRY_CODE],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.POSTCODE]:
        requiredFields[Fields.POSTCODE],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.STREET]:
        requiredFields[Fields.STREET],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.TOWN]:
        requiredFields[Fields.TOWN],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.EORI_NUMBER]:
        requiredFields[Fields.EORI_NUMBER],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER]:
        requiredFields[Fields.FDA_NUMBER],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID]:
        requiredFields[Fields.GST_VAT_PAID],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO]:
        requiredFields[Fields.DESTINATION_TAX_ID_REG_NO],
    };
  }
);

export const getInvoiceImporterRequiredFields = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getCountries,
  AuthSelectors.getAuthUser,
  ReferenceSelectors.getActiveOutboundService,
  UmsSelectors.getCustomer,
  (values, countries, authUser, selectedService, customer) => {
    const importerCountryCode = get(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const networkCode = get(
      values,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
      ""
    );
    const notifyRequired = ServiceModels.isFlagRequired(
      selectedService,
      "notifyRequired"
    );
    const importerEmailValue = get(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.EMAIL,
      ""
    );
    const importerTelephoneValue = get(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE,
      ""
    );
    const importerSelectedCountry = countries.find(
      ({ countryKey }) => countryKey === importerCountryCode
    );
    const isImporterPostcodeRequired = ShipmentModels.shouldCheckPostcode(
      importerSelectedCountry
    );
    const isBusiness = get(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
    );
    const hideOptions = ServiceModels.shouldHideBusinessConsumerOptions(
      selectedService,
      customer.shippingVersion
    );

    return {
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE]: true,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.STREET]: true,
      // @see https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_SYs6aAmGAqCIn3kw.html
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS]:
        NewShipmentModels.isBusinessRequired(
          customer.shippingVersion,
          selectedService,
          getValue(values, ShipmentEntity.GENERATE_CUSTOMS_DATA, false)
        ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.TOWN]: true,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE]:
        networkCode && notifyRequired && !importerEmailValue,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME]:
        networkCode && notifyRequired,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.EMAIL]:
        networkCode && notifyRequired && !importerTelephoneValue,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE]:
        !!importerCountryCode.length && isImporterPostcodeRequired,
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER]:
        !hideOptions &&
        !isBusiness &&
        ServiceModels.isFlagRequired(selectedService, "pidRequired"),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER]:
        !hideOptions &&
        isBusiness &&
        ServiceModels.isFlagRequired(selectedService, "vatRequired"),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER]:
        !hideOptions &&
        isBusiness &&
        ServiceModels.isFlagRequired(selectedService, "eoriRequired"),
    };
  }
);

// @see https://it.dpduk.live/it/diagram/diag_GCJhVN6GAqAAhVnC.html?id=1633609607280
export const isVisibleDestinationTaxIdRegNo = createSelector(
  ReferenceSelectors.getActiveOutboundService,
  getGenerateCustomData,
  UmsSelectors.getCustomer,
  ServiceModels.isVisibleDestinationTaxIdRegNo
);

export const isValidShipmentForm = createSelector(
  getShipmentFormSyncErrors,
  (state, { formName }) => isValid(formName)(state),
  isSwapItOrReverseItShipmentType,
  getGenerateCustomData,
  (errors, isValid, isSwapItOrReverse, generateCustomsData) => {
    const newErrors = cloneDeep(errors);
    if (generateCustomsData) {
      return isEmpty(newErrors.invoice) && isValid;
    }
    return isValid;
  }
);

export const shouldDisablePrintButtons = createSelector(
  isValidShipmentForm,
  isDirtyShipmentForm,
  isParcelsDataValid,
  getShipmentFormSubmitErrors,
  getFormActiveField,
  isLoadingOutboundServices,
  isLoadingInboundServices,
  ShipmentModels.shouldDisablePrintButtons
);

export const getSelectedAddressBook = createSelector(
  getData,
  ({ selectedAddressBook }) => selectedAddressBook
);

export const isAddressBookSubmitting = createSelector(
  getData,
  ({ isAddressBookSubmitting }) => isAddressBookSubmitting
);

export const getSelectedReturnAddressBook = createSelector(
  getData,
  ({ selectedReturnAddressBook }) => selectedReturnAddressBook
);

export const isShippingInformationVisible = createSelector(
  UmsSelectors.getCustomer,
  ReferenceSelectors.getActiveOutboundService,
  getShipmentFormValues,
  (customer, selectedService, formValues) =>
    NewShipmentModels.isShippingInformationVisible(customer, selectedService, {
      generateCustomsData: getValue(
        formValues,
        ShipmentEntity.GENERATE_CUSTOMS_DATA,
        false
      ),
      countryCode: getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      postCode: getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      ),
    })
);

export const getInvoiceRequiredFields = createSelector(
  getInvoiceExporterRequiredFields,
  getInvoiceImporterRequiredFields,
  getGenerateCustomData,
  isSwapItOrReverseItShipmentType,
  isShippingInformationVisible,
  getCustomsFieldsConfig,
  (
    exporterRequiredFields,
    importerRequiredFields,
    generateCustomsData,
    isSwapItOrReverseItShipmentType,
    { invoiceDetailsSection, exporterImporterDetails },
    customsFieldsFlags
  ) => {
    if (!generateCustomsData && !isSwapItOrReverseItShipmentType) {
      return {};
    }

    return {
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS]:
        importerRequiredFields[
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
        ],
      [ShipmentEntity.INVOICE.EXPORT_REASON]:
        customsFieldsFlags[Fields.EXPORT_REASON],
      [ShipmentEntity.INVOICE.SHIPPING_COST]:
        customsFieldsFlags[Fields.SHIPPING_COST],
      [ShipmentEntity.INVOICE.INVOICE_TYPE]: invoiceDetailsSection,
      [ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]:
        invoiceDetailsSection,
      ...(exporterImporterDetails && {
        ...exporterRequiredFields,
        ...importerRequiredFields,
      }),
    };
  }
);

export const getShipmentRequiredFields = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ReferenceSelectors.getActiveInboundService,
  getCommonRequiredFields,
  getSelectedCountry,
  getCustomsFieldsConfig,
  isVisibleDestinationTaxIdRegNo,
  getInvoiceRequiredFields,
  getSelectedReturnCountry,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomer,
  ShipmentModels.getShipmentRequiredFields
);

export const getAllowedFields = createSelector(
  getCustomsFieldsConfig,
  getShipmentRequiredFields,
  isVisibleDestinationTaxIdRegNo,
  getShipmentFormValues,
  isCustomsFieldsVisible,
  UmsSelectors.getSecuritySettings,
  ReferenceSelectors.getActiveOutboundService,
  getShipmentTypes,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomer,
  ShipmentModels.getCommonAllowedFields
);

export const getShipmentQuery = createSelector(
  getShipmentFormValues,
  getSelectedAddressBook,
  getSelectedReturnAddressBook,
  AuthSelectors.getAuthUser,
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  ShipmentModels.getQueryShipment
);

export const isSameAddressBook = createSelector(
  getSelectedAddressBook,
  getShipmentFormValues,
  UmsSelectors.getCustomer,
  ReferenceSelectors.getActiveOutboundService,
  (addressBook, formValues, customer, selectedService) => {
    let selectedAddressBook = {
      ...pick(addressBook, [
        Fields.DELIVERY_INSTRUCTION,
        Fields.SHIPPING_REF_1,
        Fields.SHIPPING_REF_2,
        Fields.SHIPPING_REF_3,
      ]),
      address: omit(get(addressBook, "address", {}), "countryName"),
      contactDetails: omitNilValues(get(addressBook, "contactDetails", {})),
      notificationDetails: omitNilValues(
        get(addressBook, "notificationDetails", {})
      ),
    };

    let formAddressBook = {
      deliveryInstructions: get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
        ""
      ),
      shippingRef1: get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
        ""
      ),
      shippingRef2: get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
        ""
      ),
      shippingRef3: get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
        ""
      ),
      address: get(
        formValues,
        `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${ADDRESS}`,
        {}
      ),
      contactDetails: omitNilValues(
        get(
          formValues,
          `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`,
          {}
        )
      ),
      notificationDetails: omitNilValues(
        get(
          formValues,
          `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${NOTIFICATION_DETAILS}`,
          {}
        )
      ),
    };

    const hideOptions = ServiceModels.shouldHideBusinessConsumerOptions(
      selectedService,
      customer.shippingVersion
    );
    const isBusiness = getValue(
      formValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    );
    const atRisk = getValue(
      formValues,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
      false
    );
    const generateCustomsData = getValue(
      formValues,
      ShipmentEntity.GENERATE_CUSTOMS_DATA,
      false
    );
    const { businessFields, exporterImporterDetails } =
      NewShipmentModels.isShippingInformationVisible(
        customer,
        selectedService,
        {
          generateCustomsData,
          countryCode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          ),
          postCode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE,
            ""
          ),
          isBusiness,
          atRisk,
        }
      );
    const isVisibleBusinessField = NewShipmentModels.isBusinessRequired(
      customer.shippingVersion,
      selectedService,
      generateCustomsData
    );
    const isVisibleUkims = InvoiceModels.isVisibleUkims(
      {
        isBusiness,
        atRisk,
        countryCode: getValue(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        postcode: getValue(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        ),
      },
      customer.shippingVersion
    );

    formAddressBook = {
      ...formAddressBook,
      ...(isVisibleBusinessField && { isBusiness }),
      ...(businessFields && {
        atRisk,
      }),
      ...(!hideOptions &&
        exporterImporterDetails && {
          ...(isBusiness
            ? {
                vatNumber: getValue(
                  formValues,
                  ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER
                ),
                eoriNumber: getValue(
                  formValues,
                  ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER
                ),
                ...(isVisibleUkims && {
                  ukimsNumber: getValue(
                    formValues,
                    ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER
                  ),
                }),
              }
            : {
                pidNumber: getValue(
                  formValues,
                  ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER
                ),
              }),
        }),
    };

    selectedAddressBook = {
      ...selectedAddressBook,
      ...(isVisibleBusinessField && {
        isBusiness: getValue(addressBook, Fields.IS_BUSINESS, false),
      }),
      ...(businessFields && {
        atRisk: getValue(addressBook, Fields.AT_RISK, false),
      }),
      ...(!hideOptions &&
        exporterImporterDetails && {
          ...(isBusiness
            ? {
                vatNumber: addressBook?.vatNumber,
                eoriNumber: addressBook?.eoriNumber,
                ...(isVisibleUkims && {
                  ukimsNumber: addressBook?.ukimsNumber,
                }),
              }
            : {
                pidNumber: addressBook?.pid,
              }),
        }),
    };

    return isEqual(
      replaceEmptyStringToUndefined(selectedAddressBook),
      replaceEmptyStringToUndefined(formAddressBook)
    );
  }
);

export const getAddressBookQueryForStore = createSelector(
  getSelectedAddressBook,
  getShipmentFormValues,
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  AddressbookModels.getAddressBookQuery
);

export const getAddressActionMetadata = createSelector(
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  getSelectedAddressBook,
  AuthSelectors.getAuthUser,
  isSameAddressBook,
  getAddressBookQueryForStore,
  UmsSelectors.getAdvancedCustomerConfiguration,
  isAddressBookSubmitting,
  (
    { searchCriteriaValue, searchCriteriaField },
    addressBook,
    user,
    sameAddress,
    addressBookToSave,
    advancedCustomerConfiguration,
    isAddressBookSubmitting
  ) => {
    if (
      !AddressbookModels.isUserAbleToAddOrEditAddressbook(
        advancedCustomerConfiguration
      )
    ) {
      return null;
    }

    const addressBookName = get(addressBook, "shortName", "");
    const isValidAddressEntry =
      searchCriteriaField === "shortName" &&
      searchCriteriaValue &&
      (searchCriteriaValue !== addressBookName || !sameAddress);

    if (
      AddressbookModels.isAddressbookReadonly(addressBook, user) ||
      !isValidAddressEntry
    ) {
      return null;
    }

    return {
      disabled: isAddressBookSubmitting,
      text: addressBook ? S.UPDATE_ADDRESS_BOOK : S.ADD_ADDRESS_BOOK,
      addressBook: merge(cloneDeep(addressBook), addressBookToSave),
    };
  }
);

export const getCollectionDetails = createSelector(
  getShipmentFormValues,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomerAddress,
  UmsSelectors.getCustomer,
  ReferenceSelectors.getCountries,
  ShipmentModels.getCollectionDetails
);

export const getServiceQuery = formSection =>
  createSelector(
    getShipmentFormValues,
    ProfilesSelectors.getActiveProfile,
    getCollectionDetails,
    UmsSelectors.getCustomer,
    (values, profile, outboundCollectionDetails, customer) => {
      const collectionDetails =
        formSection === OUTBOUND_CONSIGNMENT
          ? outboundCollectionDetails
          : ShipmentModels.getCollectionDetailsForInbound(values);
      const servicesRelatedFields =
        formSection === OUTBOUND_CONSIGNMENT
          ? OutboundServicesRelatedFields
          : InboundServicesRelatedFields;

      return ShipmentModels.getQueryForServices(
        values,
        servicesRelatedFields,
        collectionDetails,
        profile?.profileCode,
        formSection,
        customer
      );
    }
  );

export const getInboundServiceValues = createSelector(
  getProductRelatedInboundServices,
  getShipmentFormSyncErrors,
  getShipmentFormSubmitErrors,
  (inboundServices, syncErrors, submitErrors) => {
    const services = ServiceModels.getServicesKeyValue(inboundServices);
    const isValidReturnServiceDetailsFields =
      ShipmentModels.isValidReturnServiceDetailsFields(syncErrors);
    const isDisabled =
      inboundServices.length === 0 ||
      !isValidReturnServiceDetailsFields ||
      submitErrors.inboundConsignment;
    return isDisabled ? [] : services;
  }
);

export const getInboundProductValues = createSelector(
  getInboundProductsKeyValue,
  getShipmentFormSyncErrors,
  getShipmentFormSubmitErrors,
  (products, syncErrors, submitErrors) => {
    const isValidReturnServiceDetailsFields =
      ShipmentModels.isValidReturnServiceDetailsFields(syncErrors);
    const isDisabled =
      products.length === 0 ||
      !isValidReturnServiceDetailsFields ||
      submitErrors.inboundConsignment;
    return isDisabled ? [] : products;
  }
);

export const getCommonDisabledFields = createSelector(
  getShipmentFormValues,
  UmsSelectors.getAdvancedCustomerConfiguration,
  getShipmentFormSyncErrors,
  getShipmentFormSubmitErrors,
  getProductsKeyValue,
  getGenerateCustomsSettings,
  getCustomsFieldsConfig,
  getInboundProductValues,
  getInboundServiceValues,
  getSelectedCountry,
  ReferenceSelectors.getActiveOutboundService,
  getOutboundServicesForCurrentUser,
  (
    formValues,
    { noManualAddressEntry, noManualContactDetailsEntry },
    syncErrors,
    submitErrors,
    productsKeyValue,
    { dropdownDisabled },
    { customsValueDisabled },
    returnProductValues,
    returnServiceValues,
    selectedCountry,
    selectedService,
    services
  ) => {
    const productCode = get(selectedService, "product.productKey");
    const servicesKeyValue = ServiceModels.getProductRelatedServices(
      productCode,
      services
    );
    const isProductDropdownDisabled = ShipmentModels.isProductDropdownDisabled(
      syncErrors,
      submitErrors,
      productsKeyValue
    );
    const isServiceDropdownDisabled = ShipmentModels.isServiceDropdownDisabled({
      syncErrors,
      submitErrors,
      services: servicesKeyValue,
      productCode,
      selectedCountry,
    });

    return {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .ORGANISATION]: noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]: isProductDropdownDisabled,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]:
        isServiceDropdownDisabled,
      [ShipmentEntity.GENERATE_CUSTOMS_DATA]: dropdownDisabled,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]: customsValueDisabled,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME]: noManualContactDetailsEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE]: noManualContactDetailsEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL]: noManualContactDetailsEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .MOBILE]: noManualContactDetailsEntry,
      [ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT]: !returnProductValues.length,
      [ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]:
        !returnServiceValues.length,
      [ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]: true,
      [ShipmentEntity.INVOICE.TOTAL_WEIGHT]: true,
      // @see https://it.dpduk.live/version/customer-shipping/sprint-1.21/diag_P.yN.j6GAqAAhT3W.html?id=1641985502013
      [ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
        ShipmentModels.isSwapItShipmentType(
          formValues?.[ShipmentEntity.SHIPMENT_TYPE]
        ),
      [ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
        ShipmentModels.isSwapItShipmentType(
          formValues?.[ShipmentEntity.SHIPMENT_TYPE]
        ),
    };
  }
);

export const getReturnCountriesKeyValue = createSelector(
  ReferenceSelectors.getCountriesKeyValue,
  countries => countries?.slice(0, 2)
);

export const getExporterInvoiceDataCard = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ReferenceSelectors.getCountriesKeyValue,
  getTouchedErrorFields(EXPORTER_DETAILS),
  UmsSelectors.getCustomer,
  (
    formValues,
    selectedService,
    countries,
    touchedErrorFields,
    { shippingVersion }
  ) => {
    const { fdaRegistrationNumber, ...restFields } =
      ServiceModels.isVisibleExporterTaxRequiredFields(
        selectedService,
        get(
          formValues,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
          ""
        ),
        shippingVersion
      );

    return CardModels.mapExporterInvoiceDetails(
      ShipmentModels.getInitialInvoiceExporterDetails(
        formValues,
        shippingVersion
      ),
      {
        ...restFields,
        [Fields.EORI_NUMBER]: true,
        [Fields.FDA_NUMBER]:
          fdaRegistrationNumber &&
          !SettingsModels.isNewVersion(shippingVersion),
        [Fields.UKIMS_NUMBER]: InvoiceModels.isVisibleUkims(
          {
            isBusiness: get(
              formValues,
              ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
              false
            ),
            atRisk: get(
              formValues,
              ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
              false
            ),
            countryCode: getValue(
              formValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                .COUNTRY_CODE,
              ""
            ),
            postcode: getValue(
              formValues,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                .POSTCODE,
              ""
            ),
          },
          shippingVersion
        ),
      },
      countries,
      touchedErrorFields
    );
  }
);

export const getImporterInvoiceDataCard = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getCountriesKeyValue,
  ReferenceSelectors.getActiveOutboundService,
  getTouchedErrorFields(IMPORTER_DETAILS),
  UmsSelectors.getCustomer,
  (formValues, countries, selectedService, touchedErrorFields, customer) =>
    CardModels.mapImporterInvoiceDetails(
      ShipmentModels.getInvoiceImporterDetails(formValues),
      ServiceModels.shouldHideBusinessConsumerOptions(
        selectedService,
        customer.shippingVersion
      ),
      InvoiceModels.isVisibleUkims(
        {
          isBusiness: get(
            formValues,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
            false
          ),
          atRisk: get(
            formValues,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
            false
          ),
          countryCode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          ),
          postcode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE,
            ""
          ),
        },
        customer.shippingVersion
      ),
      countries,
      touchedErrorFields
    )
);

export const getReturnDetailsReview = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getCountriesKeyValue,
  getTouchedErrorFields(INBOUND_CONSIGNMENT),
  (formValues, countries, touchedErrorFields) =>
    CardModels.mapDetails(
      ShipmentModels.getReturnDetails(formValues),
      countries,
      getValue(
        ShipmentModels.getReturnPackageDetails(formValues),
        Fields.DELIVERY_INSTRUCTION,
        ""
      ),
      touchedErrorFields,
      INBOUND_CONSIGNMENT
    )
);

export const getDeliveryDetailsReview = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getCountriesKeyValue,
  getTouchedErrorFields(OUTBOUND_CONSIGNMENT),
  (formValues, countries, touchedErrorFields) =>
    CardModels.mapDetails(
      ShipmentModels.getDeliveryDetails(formValues),
      countries,
      getValue(
        ShipmentModels.getPackageDetails(formValues),
        Fields.DELIVERY_INSTRUCTION,
        ""
      ),
      touchedErrorFields,
      OUTBOUND_CONSIGNMENT
    )
);

export const getShipmentDetailsReview = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  getAllowedFields,
  getShipmentProducts,
  getShippingFreightCost,
  getParcelsTotalValue,
  getTouchedErrorFields(OUTBOUND_CONSIGNMENT),
  ShipmentModels.getShipmentDetailsReview
);

export const getReturnPackageReview = createSelector(
  getShipmentFormValues,
  ReferenceSelectors.getActiveInboundService,
  getTouchedErrorFields(INBOUND_CONSIGNMENT),
  (formValues, selectedInboundService, touchedErrorFields) =>
    CardModels.mapReturnPackageReview(
      ShipmentModels.getReturnPackageDetails(formValues),
      selectedInboundService,
      touchedErrorFields
    )
);

export const getDeliveryInformationOptions = createSelector(
  UmsSelectors.getPreferences,
  ShipmentModels.getDeliveryInformationOptions
);

export const getInitialInvoiceData = createSelector(
  UmsSelectors.getPreferences,
  UmsSelectors.getCustomer,
  getSelectedCountry,
  getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomerAddress,
  UmsSelectors.getCustomerPrefs,
  getSelectedAddressBook,
  (
    preferences,
    customer,
    selectedCountry,
    shipment,
    selectedService,
    profile,
    userCustomerAddress,
    customerPrefs,
    selectedAddressBook
  ) =>
    InvoiceModels.getInitialInvoiceData({
      preferences,
      customer,
      selectedCountry,
      shipment,
      selectedService,
      profile,
      userCustomerAddress,
      customerPrefs,
      selectedAddressBook,
    })
);

export const getSelectedShipmentCurrency = createSelector(
  getShipmentFormValues,
  values =>
    getValue(values, ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY, S.GBP)
);

export const isEditProductFormActive = createSelector(
  state => getFormNames()(state),
  state => getFormValues(EDIT_PRODUCT_FORM)(state),
  (formNames, editProductFormValues) =>
    includes(formNames, EDIT_PRODUCT_FORM) && !!editProductFormValues
);

export const getProducts = createSelector(getShipmentFormValues, formValues =>
  getValue(
    formValues,
    `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0]["products"]`,
    []
  )
);

export const getProductDescriptionRequiredFields = createSelector(
  getShipmentFormValues,
  createShipmentValues => ({
    [ProductDescriptionsEntity.PRODUCT_DESCRIPTION]: !getValue(
      createShipmentValues,
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0]["products"]`,
      []
    ).length,
  })
);
