import { isEmpty, isNull, set } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import {
  autofill,
  change,
  clearAsyncError,
  hasSubmitFailed,
  reset,
  resetSection,
  stopAsyncValidation,
  stopSubmit,
} from "redux-form";

import { isAbortError } from "@dpdgroupuk/fetch-client";
import { REQUIRED_TYPE, SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";

import styles from "~/components/ShipmentReview/ShipmentReview.module.scss";
import {
  EXPORTER_DETAILS,
  ExporterDetailsEntity,
  IMPORTER_DETAILS,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import * as S from "~/constants/strings";
import withInvisibleErrorHandler from "~/hocs/withInvisibleErrorHandler";
import {
  InvoiceModels,
  LiabilityModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";
import {
  AuthSelectors,
  ProfilesActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { isIgnoredError } from "~/utils/error";
import { getValue } from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { formatMessage } from "~/utils/string";
import { isWeekend } from "~/utils/date";
import { defineParcels } from "~/pages/Shipment/redux/actions";
import { SHIP_TO_SHOP } from "~/constants/services";

export default compose(
  withState("abortController", "setAbortController", new AbortController()),
  withState(
    "isEditingProductDescription",
    "setIsEditingProductDescription",
    false
  ),
  withSnackbar,
  withInvisibleErrorHandler,
  connect(
    (state, { pageConfig }) => ({
      hasSubmitFailed: hasSubmitFailed(pageConfig.formName)(state),
      profiles: ProfilesSelectors.getProfilesKeyValue(state),
      profilesRaw: ProfilesSelectors.getProfiles(state),
      profile: ProfilesSelectors.getActiveProfile(state, pageConfig),
      user: AuthSelectors.getAuthUser(state),
      customer: UmsSelectors.getCustomer(state),
      isHiddenProfile: ProfilesSelectors.isHiddenProfile(state),
      customerPrefs: UmsSelectors.getCustomerPrefs(state),
      userCustomerAddress: UmsSelectors.getCustomerAddress(state),
      preferences: UmsSelectors.getPreferences(state),
      shippingSettings: UmsSelectors.getShippingSettings(state),
      currentPrinterType: UmsSelectors.getPrinterType(state),
      advancedCustomerConfiguration:
        UmsSelectors.getAdvancedCustomerConfiguration(state),
      securitySettings: UmsSelectors.getSecuritySettings(state),
      // NOTE: selectedCountry is left here for compatibility with its usages, considering replacing it later
      selectedCountry: ShipmentSelectors.getSelectedCountry(state, pageConfig),
      selectedDeliveryCountry: ShipmentSelectors.getSelectedCountry(
        state,
        pageConfig
      ),
      selectedService: ReferenceSelectors.getActiveOutboundService(state),
      selectedInboundService: ReferenceSelectors.getActiveInboundService(state),
      fieldsMeta: ShipmentSelectors.getFields(state, pageConfig),
      generateCustomsData: ShipmentSelectors.getGenerateCustomData(
        state,
        pageConfig
      ),
      isSwapItOrReverseItShipmentType:
        ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig),
      productRequiredFields: ShipmentSelectors.getProductRequiredFieldsArray(
        state,
        pageConfig
      ),
      customsFieldsFlags: ShipmentSelectors.getCustomsFieldsConfig(
        state,
        pageConfig
      ),
      // TODO: Rename to shipmentFormValues later
      createShipmentValues: ShipmentSelectors.getShipmentFormValues(
        state,
        pageConfig
      ),
      selectedAddressBook: ShipmentSelectors.getSelectedAddressBook(state),
      exportReasons: ReferenceSelectors.getExportReasons(state),
      countries: ReferenceSelectors.getCountriesKeyValue(state),
      countryList: ReferenceSelectors.getCountries(state),
      returnCountries: ShipmentSelectors.getReturnCountriesKeyValue(state),
      shipmentType: ShipmentSelectors.getShipmentType(state, pageConfig),
      products: ShipmentSelectors.getShipmentProducts(state, pageConfig),
      shippingFreightCost: ShipmentSelectors.getShippingFreightCost(
        state,
        pageConfig
      ),
      parcelsTotalValue: ShipmentSelectors.getParcelsTotalValue(
        state,
        pageConfig
      ),
      addressAction: ShipmentSelectors.getAddressActionMetadata(
        state,
        pageConfig
      ),
      disableButton: ShipmentSelectors.shouldDisablePrintButtons(
        state,
        pageConfig
      ),
      selectedReturnCountry: ShipmentSelectors.getSelectedReturnCountry(
        state,
        pageConfig
      ),
      returnServiceValues: ShipmentSelectors.getInboundServiceValues(
        state,
        pageConfig
      ),
      returnProductValues: ShipmentSelectors.getInboundProductValues(
        state,
        pageConfig
      ),
      isLoadingInboundServices:
        ShipmentSelectors.isLoadingInboundServices(state),
      createShipmentSyncErrors: ShipmentSelectors.getShipmentFormSyncErrors(
        state,
        pageConfig
      ),
      createShipmentSubmitErrors: ShipmentSelectors.getShipmentFormSubmitErrors(
        state,
        pageConfig
      ),
      createShipmentAsyncErrors: ShipmentSelectors.getShipmentFormAsyncErrors(
        state,
        pageConfig
      ),
      isValidReturnContactSection:
        ShipmentSelectors.isValidReturnContactSection(state, pageConfig),
      productsKeyValue: ShipmentSelectors.getProductsKeyValue(
        state,
        pageConfig
      ),
      networksKeyValue: ShipmentSelectors.getNetworksKeyValue(
        state,
        pageConfig
      ),
      outboundServices: ShipmentSelectors.getOutboundServicesForCurrentUser(
        state,
        pageConfig
      ),
      inboundServices: ShipmentSelectors.getInboundServicesForCurrentUser(
        state,
        pageConfig
      ),
      isLoadingOutboundServices:
        ShipmentSelectors.isLoadingOutboundServices(state),
      shipmentTypes: ShipmentSelectors.getShipmentTypes(state, pageConfig),
      currencies: ReferenceSelectors.getCurrenciesKeyValue(state),
      isParcelsDataValid: ShipmentSelectors.isParcelsDataValid(
        state,
        pageConfig
      ),
      parcelsErrors: ShipmentSelectors.getParcelsDataErrors(state, pageConfig),
      pageFormState: ShipmentSelectors.getFormState(state, pageConfig),
      editProductRequiredFields: ShipmentSelectors.getProductRequiredFields(
        state,
        pageConfig
      ),
      editProductHiddenFields: ShipmentSelectors.getProductHiddenFields(
        state,
        pageConfig
      ),
      exporterInvoiceDataCard: ShipmentSelectors.getExporterInvoiceDataCard(
        state,
        pageConfig
      ),
      importerInvoiceDataCard: ShipmentSelectors.getImporterInvoiceDataCard(
        state,
        pageConfig
      ),
      returnDetailsReview: ShipmentSelectors.getReturnDetailsReview(
        state,
        pageConfig
      ),
      deliveryDetailsReview: ShipmentSelectors.getDeliveryDetailsReview(
        state,
        pageConfig
      ),
      shipmentDetailsReview: ShipmentSelectors.getShipmentDetailsReview(
        state,
        pageConfig
      ),
      returnPackageReview: ShipmentSelectors.getReturnPackageReview(
        state,
        pageConfig
      ),
      deliveryInformationOptions:
        ShipmentSelectors.getDeliveryInformationOptions(state),
      collectionDetails: ShipmentSelectors.getCollectionDetails(
        state,
        pageConfig
      ),
      uniqueSenderRef1: ShipmentSelectors.getUniqueSenderRef1(state),
    }),
    (
      dispatch,
      {
        snackbar,
        notifier,
        pageConfig,
        prompt,
        abortController,
        authUser,
        allowedFields,
        isEditingProductDescription,
        reInitializeForm,
      }
    ) => ({
      fetchCountries: notifier.runAsync(
        query => dispatch(ReferenceActions.fetchCountries(query)),
        { entityName: S.COUNTRIES }
      ),
      fetchCurrencies: notifier.runAsync(
        () => dispatch(ReferenceActions.fetchCurrencies()),
        { entityName: S.CURRENCY }
      ),
      fetchExportReasons: notifier.runAsync(
        query =>
          dispatch(
            ReferenceActions.fetchExportReasons(query, {
              signal: abortController.signal,
            })
          ),
        { entityName: S.EXPORT_REASONS }
      ),
      fetchProfiles: notifier.runAsync(
        () =>
          dispatch(
            ProfilesActions.fetchProfiles({
              profileCodes: authUser.user.userProfile,
            })
          ),
        { entityName: S.PROFILES }
      ),
      onClickCopyNumber: shipmentId =>
        navigator.clipboard
          .writeText(shipmentId)
          .then(() =>
            snackbar.showSuccess({ message: S.CONSIGNMENT_NUMBER_COPIED })
          ),
      handleSubmitShipment: dispatch(
        ShipmentActions.handleSubmitShipment({
          pageConfig,
          notifier,
          isEditingProductDescription,
        })
      ),
      handleSubmitAddressbook: dispatch(
        ShipmentActions.handleSubmitAddressbook({ pageConfig, notifier })
      ),
      createOrUpdateAddressbook: notifier.runAsync(
        async ({ addressBookId, ...addressBook }) => {
          try {
            const addressbook = await dispatch(
              ShipmentActions.createOrUpdateAddressBook(
                addressBookId,
                addressBook,
                abortController
              )
            );
            dispatch(
              change(
                pageConfig.formName,
                `${OUTBOUND_CONSIGNMENT}.addressBookId`,
                addressbook.addressBookId
              )
            );
            snackbar.showSuccess({
              message: addressBookId
                ? S.EDIT_ADDRESS_BOOK
                : S.CREATE_ADDRESS_BOOK,
            });
          } catch (error) {
            if (isIgnoredError(error)) return;

            const errors = error?.errors
              ?.filter(({ message }) => message)
              .map(({ message }) => `${S.ADDRESS_BOOK} ${message}`)
              .join("\n");

            if (errors) {
              snackbar.showAlert({ message: errors });
            } else {
              throw error;
            }
          }
        },
        { entityName: S.ADDRESS_BOOK }
      ),
      updateReturnPackageFields: ({ totalWeight, numberOfParcels }) => {
        dispatch(
          autofill(
            pageConfig.formName,
            ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
            totalWeight
          )
        );
        dispatch(
          autofill(
            pageConfig.formName,
            ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
            // NOTE: blur this field convert value to string
            // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
            numberOfParcels?.toString()
          )
        );
      },
      updateReturnCountry: () =>
        dispatch(
          autofill(
            pageConfig.formName,
            ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            S.GB
          )
        ),
      clearInboundServices: query =>
        dispatch(ReferenceActions.clearInboundServices(query)),
      populateDefaultCustomsValue: value =>
        dispatch(
          autofill(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
            value
          )
        ),
      onCountryChange: selection => {
        if (selection?.value !== S.GB) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.SHIPMENT_TYPE,
              SHIPMENT_TYPES.NO_TYPE
            )
          );
        }

        dispatch(
          ShipmentActions.changeCountry(
            pageConfig,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            selection.value
          )
        );
      },
      saveInvoiceExporterDetails: values => {
        dispatch(
          initializeForm(pageConfig.formName, {
            [INVOICE]: {
              [EXPORTER_DETAILS]: {
                ...values,
              },
            },
          })
        );
        // remove data from invisible field
        if (isNull(values[ExporterDetailsEntity.GST_VAT_PAID])) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID,
              undefined
            )
          );
        }
      },
      saveInvoiceImporterDetails: values =>
        dispatch(
          initializeForm(pageConfig.formName, {
            [INVOICE]: {
              [IMPORTER_DETAILS]: {
                ...values,
              },
            },
          })
        ),
      createProduct: product =>
        dispatch(ShipmentActions.createParcelProduct(product, pageConfig)),
      deleteProduct: selectedProduct =>
        dispatch(
          ShipmentActions.deleteParcelProduct(
            pageConfig.formName,
            selectedProduct
          )
        ),
      printWithLabelConfirmation: () =>
        new Promise((resolve, reject) => {
          prompt.showConfirmationDelete({
            header: S.PRINT_NOW,
            message: S.PRINT_INVOICE_NOW_CONFIRMATION_MESSAGE,
            bodyClassName: styles.promptStyle,
            onConfirm: resolve,
            onReject: reject,
          });
        }),
      defineParcels: () => dispatch(defineParcels(pageConfig)),
      resetPackageContent: () =>
        dispatch(
          resetSection(pageConfig.formName, "outboundConsignment.parcels")
        ),
      clearSelectedReturnAddressBook: () =>
        dispatch(ShipmentActions.clearAddressBook(pageConfig.formName)),
      resetShippingInformationFormSection: () =>
        dispatch(ShipmentActions.resetShippingInformation(pageConfig)),
      clearExportReasons: () => dispatch(ReferenceActions.clearExportReasons()),
      clearShippingInformationFormSection: () =>
        dispatch(resetSection(pageConfig.formName, INVOICE)),
      resetExportReason: () =>
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.INVOICE.EXPORT_REASON,
            undefined
          )
        ),
      generateUniqueSenderRef1: notifier.runAsync(
        () => dispatch(ShipmentActions.generateUniqueReference1()),
        { entityName: S.SENDER_REFERENCE }
      ),
      onGstChange: (event, newValue, previousValue) => {
        if (!newValue) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
              undefined
            )
          );
        }
        if (!previousValue && newValue) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
              null
            )
          );
        }
      },
      setDefaultDeliveryDescription: () =>
        dispatch(
          ShipmentActions.setDefaultDeliveryDescription(
            pageConfig,
            allowedFields
          )
        ),
      setDefaultCurrency: () =>
        dispatch(ShipmentActions.setDefaultCurrency(pageConfig)),
      clearReturnDetailsData: () => {
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.SHIPMENT_TYPE,
            SHIPMENT_TYPES.NO_TYPE
          )
        );
        dispatch(ShipmentActions.resetInboundConsignment(pageConfig.formName));
        dispatch(ShipmentActions.clearAddressBook(pageConfig.formName));
        dispatch(reset(RETURN_DETAILS_SEARCH_FORM));
      },
    })
  ),
  withHandlers({
    onGenerateCustomsDataChange:
      ({
        defineParcels,
        resetPackageContent,
        resetShippingInformationFormSection,
        clearShippingInformationFormSection,
        clearExportReasons,
        dispatch,
        pageConfig,
        setDefaultDeliveryDescription,
        outboundServices,
        customer,
        user,
        profile,
        createShipmentValues,
      }) =>
      (...args) => {
        const [, value, , , forbidFilterServices] = args;
        // We have to update manually Redux state (case change service manually or automatically)
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.GENERATE_CUSTOMS_DATA,
            value
          )
        );

        if (value) {
          // CASE: required for manually change generateCustomsData
          resetShippingInformationFormSection();
          setDefaultDeliveryDescription();
          // NOTE: on new version we should fill all parcelIds from shipment which was created on old version
          defineParcels();
        } else {
          clearShippingInformationFormSection();
          clearExportReasons();

          if (!isNull(value)) {
            resetPackageContent();
          }

          // NOTE: only if ECD was changed
          if (!forbidFilterServices) {
            const availableServices = ServiceModels.getServicesForUser(
              user,
              profile,
              outboundServices,
              customer,
              {
                ...createShipmentValues,
                [ShipmentEntity.GENERATE_CUSTOMS_DATA]: value,
              },
              true
            );
            const service = ServiceModels.getServiceByNetworkCode(
              availableServices,
              getValue(
                createShipmentValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                ""
              )
            );

            if (!service) {
              //NOTE: we have to reset networkCode if selectedService is unavailable (case select ECD=NO for international shipments)
              dispatch(
                change(
                  pageConfig.formName,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
                  undefined
                )
              );
              dispatch(ReferenceActions.setActiveOutboundService({}));
            }
          }
        }
      },
    onLiabilityChange:
      ({ dispatch, pageConfig }) =>
      (event, newValue) =>
        dispatch(ShipmentActions.onLiabilityChange(newValue, pageConfig)),
    filterDate:
      ({ preferences }) =>
      date =>
        getValue(preferences, "shippingDefaults.enableWeekend", false) ||
        !isWeekend(date),
    getDatesRange:
      ({ preferences, advancedCustomerConfiguration }) =>
      () =>
        ShipmentModels.getAvailableDateRange(
          getValue(preferences, "shippingDefaults.enableWeekend", false),
          getValue(advancedCustomerConfiguration, "forwardDateOver7Days", false)
        ),
  }),
  withHandlers({
    handleNetworkChange:
      ({
        createShipmentValues,
        onGenerateCustomsDataChange,
        preferences,
        profile,
        exportReasons,
        selectedCountry,
        resetExportReason,
        customer,
        dispatch,
        pageConfig,
      }) =>
      (service, shouldResetGenerateCustomsData) => {
        const currentInvoiceSettings =
          ShipmentModels.getGenerateCustomsDataSettings(
            service,
            preferences,
            profile,
            createShipmentValues,
            customer
          );
        const newExportReasons = InvoiceModels.getReasons(
          exportReasons,
          selectedCountry,
          service
        );
        const shouldResetExportReasonValue =
          InvoiceModels.shouldResetShipmentExportReason(
            createShipmentValues,
            newExportReasons
          );

        if (
          shouldResetGenerateCustomsData ||
          // NOTE: case when previous service is unavailable (have to change generateCustomsData for new service)
          (isNull(
            getValue(
              createShipmentValues,
              ShipmentEntity.GENERATE_CUSTOMS_DATA,
              null
            )
          ) &&
            !isNull(currentInvoiceSettings.preSelectedValue))
        ) {
          onGenerateCustomsDataChange(
            null,
            currentInvoiceSettings.preSelectedValue,
            null,
            null,
            true
          );
        }

        if (shouldResetExportReasonValue) {
          resetExportReason();
        }

        dispatch(ShipmentActions.updateParcels(pageConfig, service));
      },
    handleLiability:
      ({ securitySettings, dispatch, pageConfig }) =>
      (service, prevService) => {
        const allowedLiability =
          LiabilityModels.getIsExtendedLiabilityFlagAllowed(
            securitySettings,
            service
          );
        const prevAllowedLiability =
          LiabilityModels.getIsExtendedLiabilityFlagAllowed(
            securitySettings,
            prevService
          );
        // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
        // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
        if (service && allowedLiability !== prevAllowedLiability) {
          dispatch(
            ShipmentActions.setupExtendedLiability(allowedLiability, pageConfig)
          );
        }

        if (!allowedLiability && prevAllowedLiability) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
              undefined
            )
          );
        }
      },
    handleTaxIdAndGst:
      ({
        dispatch,
        pageConfig,
        preferences,
        profile,
        customer,
        createShipmentValues,
        prevFormValues,
      }) =>
      (service, prevService) => {
        const { preSelectedValue: generateCustomsData } =
          ShipmentModels.getGenerateCustomsDataSettings(
            service,
            preferences,
            profile,
            createShipmentValues,
            customer
          );
        const { preSelectedValue: prevGenerateCustomsData } =
          ShipmentModels.getGenerateCustomsDataSettings(
            prevService,
            preferences,
            profile,
            prevFormValues,
            customer
          );
        const isVisibleTaxId = ServiceModels.isVisibleDestinationTaxIdRegNo(
          service,
          generateCustomsData,
          customer
        );

        if (
          isVisibleTaxId !==
          ServiceModels.isVisibleDestinationTaxIdRegNo(
            prevService,
            prevGenerateCustomsData,
            customer
          )
        ) {
          dispatch(
            ShipmentActions.setupAdditionalDataRequirements(
              isVisibleTaxId,
              pageConfig
            )
          );
        }
      },
    handleFdaNumber:
      ({ dispatch, pageConfig, preferences, profile }) =>
      (service, prevService) => {
        const isVisibleFdaNumber = ServiceModels.isVisibleFdaNumber(service);
        const isVisiblePrevFdaNumber =
          ServiceModels.isVisibleFdaNumber(prevService);

        if (!isVisiblePrevFdaNumber && isVisibleFdaNumber) {
          const fdaRegistrationNumber = !isEmpty(profile?.invDetailsPostcode)
            ? profile?.invFdaRegistrationNumber
            : preferences.prefsInvoiceSettings.fdaRegistrationNumber;

          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
              fdaRegistrationNumber
            )
          );
        } else if (isVisiblePrevFdaNumber && !isVisibleFdaNumber) {
          dispatch(
            change(
              pageConfig.formName,
              ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
              undefined
            )
          );
        }
      },
    handleOutboundTotalWeight:
      ({ createShipmentValues, reInitializeForm }) =>
      service => {
        const totalWeight = ServiceModels.roundTotalWeight(
          getValue(
            createShipmentValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
            ""
          ),
          getValue(
            createShipmentValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE,
            ""
          ),
          getValue(service, "networkKey", "")
        );

        // NOTE: use reInitializeForm to trigger onBlurServiceRelatedField
        reInitializeForm({
          [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]: totalWeight,
        });
      },
    validateOnUniqueShipmentRef1:
      ({
        pageConfig,
        shippingSettings,
        dispatch,
        snackbar,
        prompt,
        abortController,
      }) =>
      async senderRef1Value => {
        dispatch(
          clearAsyncError(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1
          )
        );
        // @see: https://it.dpduk.live/version/customer-shipping/sprint-2.9/diag_qKAP2EGGAqCIfLZH.html
        if (shippingSettings.sendersRefUnique && !isEmpty(senderRef1Value)) {
          const { isUnique, consignmentNumber } = await dispatch(
            ShipmentActions.validateUniqueReference1(senderRef1Value, {
              signal: abortController.signal,
            })
          ).catch(err => {
            if (isAbortError(err)) {
              throw err;
            }

            snackbar.showAlert({
              message:
                "An error has occurred while validating your unique ref 1.",
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
            throw err;
          });

          if (!isUnique) {
            prompt.showInfo({
              header: "A validation error has been encountered",
              message: S.UNIQUE_REFERENCE_1_ERROR_MESSAGE_$(consignmentNumber),
            });

            const errors = set(
              {},
              ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
              S.VALUE_FOR_$_IS_ALREADY_IN_USE(S.YOUR_REFERENCE_1)
            );
            dispatch(stopAsyncValidation(pageConfig.formName, errors));
            throw errors;
          }
        }
      },
  }),
  withHandlers({
    onChangeShipmentType:
      ({
        createShipmentValues,
        updateReturnPackageFields,
        updateReturnCountry,
        clearInboundServices,
        pageConfig,
        dispatch,
        clearSelectedReturnAddressBook,
      }) =>
      (e, value, dropdownRef) => {
        let returnPackageFields = {};

        if (ShipmentModels.isSwapItOrReverseItShipmentType(value)) {
          updateReturnCountry();
          clearInboundServices();

          if (ShipmentModels.isSwapItShipmentType(value)) {
            returnPackageFields =
              ShipmentModels.getPackageDetails(createShipmentValues);
          } else if (ShipmentModels.isReverseItShipmentType(value)) {
            returnPackageFields = { totalWeight: 1, numberOfParcels: 1 };

            dispatch(
              ShipmentActions.clearReturnItemDescription(pageConfig.formName)
            );
          }
        } else {
          // Clear return details related data
          dispatch(
            ShipmentActions.resetInboundConsignment(pageConfig.formName)
          );
          dispatch(ReferenceActions.clearInboundServices());
          clearSelectedReturnAddressBook();
          dispatch(reset(RETURN_DETAILS_SEARCH_FORM));
        }

        updateReturnPackageFields(returnPackageFields);
        dropdownRef.current.blur();

        let numberOfParcelsError;

        if (
          ShipmentModels.isSwapItShipmentType(value) &&
          parseInt(returnPackageFields.numberOfParcels) !== 1
        ) {
          // @see https://it.dpduk.live/it/diagram/diag_M972Vp6C48kIGEwf.html?id=1645456385944
          numberOfParcelsError = formatMessage(
            S.MAXIMUM_NUMBER_OF_PARCELS_FOR_$_IS_$,
            S.CONSIGNMENT_DIRECTION.INBOUND,
            "Swap It",
            1
          );
        }

        dispatch(
          stopSubmit(pageConfig.formName, {
            inboundConsignment: {
              numberOfParcels: numberOfParcelsError,
            },
          })
        );
      },
    confirmPrintHandler:
      ({
        generateCustomsData,
        selectedService,
        printWithLabelConfirmation,
        printWithInvoice,
        printWithoutInvoice,
        overlay,
      }) =>
      async shipment => {
        if (
          generateCustomsData &&
          [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL].includes(
            selectedService.proformaPrintRequired
          )
        ) {
          if (
            ServiceModels.isFlagOptional(
              selectedService,
              "proformaPrintRequired"
            )
          ) {
            try {
              overlay.hide();
              await printWithLabelConfirmation();
              overlay.show();
              // NOTE: pass true because shipment passed from BE response without full body
              await printWithInvoice([shipment], true);
            } catch (error) {
              await printWithoutInvoice([shipment]);
            }
          } else {
            // NOTE: pass true because shipment passed from BE response without full body
            await printWithInvoice([shipment], true);
          }
        } else {
          await printWithoutInvoice([shipment]);
        }
      },
    onInboundNetworkChange:
      ({ inboundServices, dispatch }) =>
      (e, value) => {
        const service = ServiceModels.getServiceByNetworkCode(
          inboundServices,
          value
        );
        dispatch(ReferenceActions.setActiveInboundService(service));
      },
    onNetworkChange:
      ({
        outboundServices,
        handleLiability,
        handleNetworkChange,
        handleTaxIdAndGst,
        handleFdaNumber,
        handleOutboundTotalWeight,
        dispatch,
        csvService,
        setCsvService,
        customer,
        clearReturnDetailsData,
      }) =>
      (e, value, prevValue) => {
        const service = ServiceModels.getServiceByNetworkCode(
          outboundServices,
          value
        );
        const prevService = ServiceModels.getServiceByNetworkCode(
          outboundServices,
          prevValue
        );

        dispatch(ReferenceActions.setActiveOutboundService(service));

        handleLiability(service, prevService);
        handleNetworkChange(
          service,
          !SettingsModels.isNewVersion(customer.shippingVersion)
        );

        if (SHIP_TO_SHOP.includes(getValue(service, "networkKey", ""))) {
          clearReturnDetailsData();
        }
        handleTaxIdAndGst(service, prevService);
        handleFdaNumber(service, prevService);
        handleOutboundTotalWeight(service);
        // @see: comments from https://geopost.jira.com/browse/CSHIP-6433
        // NOTE: must clear after first manual changing
        csvService && setCsvService();
      },
  }),
  withHandlers({
    onClickPrint: ({ banner, notifier, uiFields, showInvisibleFieldError }) =>
      notifier.runAsync(
        showInvisibleFieldError(
          async (printNow, printShipment) => {
            banner.hideAll();
            await printShipment(printNow);
          },
          { uiFields }
        ),
        {
          mapErrors: ShipmentModels.getShipmentFormErrors,
          entityName: S.SHIPMENT_ENTITY,
        }
      ),
  })
);
